<template>
  <v-dialog v-model="mostrar" name="verificacion-modal" :adaptive="true" :width="height ==1? '95%':height==2? '85%':'50%'" height="auto" persistent @click:outside="$emit('cerrar')" @keydown.esc="$emit('cerrar')">
    <v-card class="center" height="100%" >
      <v-toolbar color="green" dark class="d-flex justify-center">
        <v-icon left color="white">mdi-certificate</v-icon>
        <v-toolbar-title >Constancia válida</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="font-family: Arial, Helvetica, sans-serif;" class="grey--text text--darken-3">
        <section v-if="mostrarDatos"  class="pa-5">
          <v-row no-gutters>
            <v-icon color="green" left>mdi-check-decagram</v-icon>
              Consejero Propietario ante el H. Consejo General Universitario, durante el
              periodo 2023-2024. <br>
              Esta constancia digital se emitió de forma segura mediante la Universidad de Guadalajara y la información que
              contiene es válida.
          </v-row>
        </section>

        <section v-if="!mostrarDatos" class="container">
          <img class="loadImg" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
            alt="Cargando Datos">Cargando Datos
        </section>

        <hr>

        <section v-if="mostrarEmisor" class="pa-5">
          <v-row no-gutters>
            <v-icon left color="green" >mdi-check-bold</v-icon>
            El emisor es parte del Sistema de Constancias de la Secretaría General. <br>
          </v-row>
          <v-row no-gutters align="center">
            <!--TODO validar src de esta imagen-->
            <img
              src="http://www.hcgu.udg.mx/sites/default/files/webcgu2015_2.png"
              alt="UdeG" width="60px">
            <h3 class="ml-1">Consejo General Universitario.</h3>
          </v-row>
        </section>

        <section v-if="!mostrarEmisor" class="container">
          <img class="loadImg" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt="Cargando Emisor">
          Cargando Emisor
        </section>
        <hr>

        <section v-if="mostrarPropietario" class="pa-5">
          El propietario del documento es: <br>
          <strong>{{ nombre }}.</strong>
        </section>

        <section v-if="!mostrarPropietario" class="container">
          <img class="loadImg" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt="Cargando Propietario">
          Cargando Propietario
        </section>

        <hr>

        <section v-if="mostrarEstado" class="pa-5">
          <v-row  no-gutters align="start" >
           <span class="grey--text text--darken-1 subtitle-2">Emitida: {{fecha()}}</span>
          </v-row>
        </section>

        <section v-if="!mostrarEstado" class="container">
          <img class="loadImg" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt="Cargando Datos">
          Cargando Estado
        </section>
        <v-card-actions v-if="!loading" class="text-button">
          <v-spacer></v-spacer>
          <v-btn  small outlined @click="$emit('cerrar')">Cerrar</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
export default {
  props: ['mostrar', 'datosValidos'],
  computed: {
    height() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 1
                case 'sm': return 2
                case 'md': return 3
                case 'lg': return 4
                case 'xl': return 5
                default: return null
            }
        },
  },
  data() {
    return {
      nombre: this.datosValidos.usuario.nombre,
      muestraModal: false,
      mostrarDatos: false,
      mostrarEmisor: false,
      mostrarPropietario: false,
      mostrarEstado: false,
      loading: true
    };
  },
  async created() {
    await this.sleep(500);
    this.mostrarDatos = true;
    await this.sleep(250);
    this.mostrarEmisor = true;
    await this.sleep(125);
    this.mostrarPropietario = true;
    await this.sleep(50);
    this.mostrarEstado = true;
    this.loading = false;
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    fecha(){
      let fecha = DateTime.fromISO(this.datosValidos.consejo.fechaConstancia);
      return fecha.toLocaleString(DateTime.DATE_MED);
    }
  }
}
</script>

<style>
.container {
  display: flex;
  align-items: center;
}

.loadImg {
  height: 50px;
  width: auto;
}
</style>