<template>
    <div>
        <v-app style="background-color: #eceff1">

            <v-main>
                <v-app-bar color="primary" app dark class="d-flex justify-center"
                    :height="height <= 2 ? '50px' : height == 3 ? '60px' : '70px'">
                    <v-toolbar-items>
                        <img src="/logo-udg.png" style="filter: invert(0.9);" class="mt-2 mb-3 mr-2" />
                    </v-toolbar-items>
                    <v-toolbar-title
                        :style="[{ 'font-family': ['Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', 'Geneva', 'Verdana', 'sans-serif'], 'text-transform': 'uppercase', 'letter-spacing': '.0.3125em' }, height <= 2 ? { 'font-size': 'small' } : { 'font-size': 'medium', }]">
                        <v-row no-gutters style="gap: 0.625em" align="center">
                            <h3>secretaria general</h3>
                        </v-row>
                    </v-toolbar-title>
                </v-app-bar>
                <div v-if="!datosValidos && !error" class="d-flex justify-center">
                    <v-card class="ma-5 pa-5 text-center" width="60%">
                        <v-icon size="150px" color="#4F5978">mdi-certificate</v-icon>
                        <p class="headline">TU CONSTANCIA ESTA CARGANDO</p>
                        <v-progress-linear indeterminate color="#9399AB"></v-progress-linear>
                    </v-card>
                </div>
                <div v-else-if="error" class="d-flex justify-center">
                    <v-card class="ma-5 pa-5 text-center" width="60%">
                        <v-icon size="150px" color="error">mdi-alert-circle</v-icon>
                        <p style="font-family:sans-serif;">Ocurrió un error al acceder a tu constancia,
                            <br />Para más informacion comunicate a:<br>
                            <span class="font-weight-bold">atencion.secretaria@administrativos.udg.mx</span>
                        </p>
                    </v-card>
                </div>
                <v-container class="d-flex justify-center flex-column" v-else
                    :style="[height <= 2 ? { width: '100%' } : height == 3 ? { width: '90%' } : { width: '60%' }]">
                    <v-card class="pa-15 grey darken-1" width="100%">
                        <v-row no-gutters justify="center">
                            <v-responsive :aspect-ratio="1.294" style="background-color: white;">
                                <img src='/udg-final.png' width="50%" style="margin: 1.5625vw 0.625vw 0.625vw 2.5vw" />
                                <p
                                    :style="[{ 'text-align': 'center', 'font-size': '1vw', 'font-family': ['Arial', 'sans-serif'], 'font-weight': 'normal' }]">
                                    <span>Se extiende la presente</span>
                                    <br /><br /><br />
                                    <span style="font-size:2.3125vw;font-family: Gotham;font-weight: bold;">CONSTANCIA
                                    </span> <br /><br /><br />
                                    <span>que acredita a:</span> <br /><br />
                                </p>
                                <p style='text-align:center;font-size:0.8125vw;font-family: Gotham;'><strong
                                        style="font-size:1.6875vw;font-family: Gotham;">{{ datosValidos.usuario.nombre
                                        }}</strong>
                                </p>
                                <p style='text-align:center;font-size:1.125vw;font-family: Arial;'>
                                    <span style="font-size:1.125vw;">como
                                        {{ datosValidos.usuario.genero == 'H' ? 'Consejero ' : 'Consejera ' }} {{
                                            datosValidos.suplente ? 'Suplente' : datosValidos.usuario.genero == 'H' ?
                                            "Propietario" : "Propietaria" }}
                                        ante el H. Consejo General Universitario,&nbsp;
                                    </span><br />
                                    <span style="font-size:1.125vw;">durante el per&iacute;odo 2023-2024, representante
                                        {{ !datosValidos.prefix ? 'del' : 'de la' }}&nbsp;
                                    </span><br />
                                    <span style="font-size:1.125vw;">{{ datosValidos.dependencia.nombre }}
                                    </span><br />
                                </p>
                                <br /><br /><br />
                                <p style='text-align:center;font-size:0.8125vw;font-family:"Times New Roman",serif;'>
                                    <span style='font-size:1.125vw;font-family:Arial,sans-serif;'>A t e n t a m e n t e
                                    </span><br />
                                    <span
                                        style='font-weight: bold ;font-size:1.125vw;font-family:Arial,sans-serif;'>&ldquo;PIENSA
                                        Y TRABAJA&rdquo;</span>
                                    <br />
                                    <em><strong style='font-size:0.9375vw;font-family:Arial,sans-serif;'>&ldquo;2023, Año
                                            del fomento a la formación integral</strong>
                                    </em><br />
                                    <em><strong style='font-size:0.9375vw;font-family:Arial,sans-serif;'>con una Red de
                                            Centros y Sistemas Multitemáticos&rdquo;</strong>
                                    </em><br />
                                    <span style='font-size:1.125vw;font-family:Arial,sans-serif;'>Guadalajara, Jalisco,
                                        octubre de 2023</span>
                                </p>
                                <br /><br />
                                <p style='text-align:center;font-size:0.8125vw;font-family:"Times New Roman",serif;'>
                                    <br />
                                    <strong><span style='font-size:1.125vw;font-family:Arial,sans-serif;'>&nbsp;Dr. Ricardo
                                            Villanueva Lomel&iacute;</span>
                                    </strong><br />
                                    <span style='font-size:1.125vw;font-family:Arial,sans-serif;'>Presidente</span>
                                </p>
                                <br /><br />
                                <p
                                    style='font-size:0.8125vw;font-family:"Times New Roman",serif;margin: 0px 0px 0px 5.625vw;'>
                                    <span
                                        style='font-size:1.125vw;font-family:Arial,sans-serif;font-weight:bold;'>&nbsp;Mtro.
                                        Guillermo Arturo G&oacute;mez Mata</span>
                                    <br />
                                    <span style='font-size:1.125vw;font-family:Arial,sans-serif;'>&nbsp; &nbsp; &nbsp;
                                        Secretario de Actas y Acuerdos</span>
                                </p>
                                <br />
                                <p style='text-align:center;font-size:0.53125vw;color:gray'>
                                    <span style="margin-bottom: 0.3125vw;">
                                        <span>Av. Ju&aacute;rez No. 976, Edificio de la Rector&iacute;a General, Piso 5,
                                            Colonia Centro C.P 44100.
                                        </span><br />
                                        <span>Guadalajara, Jalisco, M&eacute;xico. Tel. 33 3134 2222, Exts, 12428,
                                            12243, 12420 y 12457 Tel. directo 33 3134 2243
                                        </span><br />
                                        <span style='color: #000852 !important;'> www.hcgu.udg.mx
                                        </span><br /><br /><br /><br /><br /><br />
                                    </span>
                                </p>
                                <div
                                    style="position:absolute;display:flex;flex-direction: row;align-items: flex-end;bottom:0.9375vw;left:86%">
                                    <img :src="datosValidos.qr" width="55%" />
                                </div>
                                <p style='text-align: left;font-size: 0.375vw;position: absolute;bottom: 1%;left: 15%'>Sello
                                    Digital: {{ hash }}</p>
                                <!-- <img :src="imageBuffer" :width="height==1? '100%':height==2? '60%':'50%'" -->
                                <!-- style="border: 1.2px solid rgb(55, 55, 55);max-width:100%; max-height:100%" /> -->
                            </v-responsive>
                        </v-row>
                    </v-card>
                    <v-card class="pa-5 mt-5" width="100%">
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                                <h1 class="headline">CONSEJO GENERAL UNIVERSITARIO</h1><br />
                                <p class="subtitle-1">PERTENECE A</p>
                                <v-row no-gutters align="center" style="gap: 0.625em;" class="mb-4">
                                    <v-avatar color="#8AA7FF"><span color="#171D33">{{ usuario.substring(0, 2)
                                    }}</span></v-avatar>
                                    <span style="font-family:Arial, Helvetica, sans-serif;font-weight:bolder;font-size:large">{{ usuario }}</span>
                                </v-row>
                                <span class="grey--text text--darken-1 subtitle-2">IDENTIFICADOR DEL DOCUMENTO: {{ hash }}</span>
                                <v-divider class="my-4"></v-divider>
                                <v-row no-gutters>
                                    <span class="grey--text text--darken-1 subtitle-2">EMITIDA: {{ fechaTexto }} </span>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-row no-gutters
                                    :style="[height <= 2 ? { gap: '0.9375em', 'margin-top': '0.625em' } : { gap: '0.9375em' }]"
                                    justify="end">
                                    <v-btn dark color="primary lighten-1" small :loading="loading"
                                        @click.stop="verificarDialog = true"><v-icon
                                            left>mdi-certificate</v-icon>Verificar</v-btn>
                                    <v-btn color="primary lighten-1" outlined small :loading="loading"
                                        @click="descargar"><v-icon left>mdi-download</v-icon>Descargar</v-btn>
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-container>
            </v-main>
        </v-app>
        <verificar-constancia v-if="verificarDialog" :mostrar="verificarDialog" :datosValidos="datosValidos"
            @cerrar="verificarDialog = false" />
    </div>
</template>


<script>
import { mapGetters } from "vuex";
import { generarImage, generarPDF } from "./constancias.service.js";
import verificarConstancia from './validarConstancia.vue'
import { DateTime } from "luxon";
export default {
    components: { verificarConstancia },
    computed: {
        ...mapGetters(["role"]),
        height() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 1
                case 'sm': return 2
                case 'md': return 3
                case 'lg': return 4
                case 'xl': return 5
                default: return null
            }
        },
        fechaTexto() {
        let fecha = DateTime.fromISO(this.datosValidos.consejo.fechaConstancia);
        return fecha.toLocaleString(DateTime.DATE_MED);
        },
    },

    data() {
        return {
            error: false,
            loading: false,
            hash: '',
            nombre: 'nombre de prueba',
            images: "",
            usuario: '',
            verificarDialog: false,
            datosValidos: null,
        }
    },
    async created() {
        this.hash = this.$route.params.idHash;
        try {
            const serverResponse = await generarImage(this.hash)
            if (!serverResponse.ok) { this.$notificarError(serverResponse.mensaje); this.error = true }
            this.usuario = serverResponse.data.usuario.nombre;
            this.datosValidos = serverResponse.data;
        } catch (error) {
            this.$notificarError(error);
            this.error = true
        }
    },
    methods: {
        async descargar() {
            try {
                this.loading = true;
                const data = this.datosValidos
                const serverResponse = await generarPDF(this.hash, data);
                const url = window.URL.createObjectURL(new Blob([serverResponse]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `CGU_${this.datosValidos.usuario.nombre}.pdf`);
                document.body.appendChild(link);
                link.click();
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$notificarError(error);
            }
        },
        async verificar() {
            this.verificarDialog = true
        },
    
    }
}
</script>

